@font-face {
  font-family: 'my-life';
  src: url('../font/my-life.eot?43381063');
  src: url('../font/my-life.eot?43381063#iefix') format('embedded-opentype'),
       url('../font/my-life.woff2?43381063') format('woff2'),
       url('../font/my-life.woff?43381063') format('woff'),
       url('../font/my-life.ttf?43381063') format('truetype'),
       url('../font/my-life.svg?43381063#my-life') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'my-life';
    src: url('../font/my-life.svg?43381063#my-life') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "my-life";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-location:before { content: '\e75c'; } /* '' */
.icon-down-open:before { content: '\e800'; } /* '' */
.icon-left-open:before { content: '\e801'; } /* '' */
.icon-right-open:before { content: '\e802'; } /* '' */
.icon-up-open:before { content: '\e803'; } /* '' */
.icon-plus:before { content: '\e804'; } /* '' */
.icon-trash-empty:before { content: '\e805'; } /* '' */
.icon-floppy:before { content: '\e806'; } /* '' */
.icon-cancel:before { content: '\e807'; } /* '' */
.icon-picture:before { content: '\e808'; } /* '' */
.icon-right-big:before { content: '\e809'; } /* '' */
.icon-resize-full:before { content: '\e80a'; } /* '' */
.icon-resize-small:before { content: '\e80b'; } /* '' */
.icon-cog:before { content: '\e80c'; } /* '' */
.icon-chart-bar:before { content: '\e80d'; } /* '' */
.icon-attention:before { content: '\e80e'; } /* '' */
.icon-cancel-circled:before { content: '\e80f'; } /* '' */
.icon-attention-circled:before { content: '\e810'; } /* '' */
.icon-search:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-arrows-cw:before { content: '\e813'; } /* '' */
.icon-picture-1:before { content: '\e814'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-github-circled-1:before { content: '\f09b'; } /* '' */
.icon-tasks:before { content: '\f0ae'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-help:before { content: '\f128'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-level-up:before { content: '\f148'; } /* '' */
.icon-compass:before { content: '\f14e'; } /* '' */
.icon-up:before { content: '\f176'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-hourglass-1:before { content: '\f251'; } /* '' */
.icon-map-pin:before { content: '\f276'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map:before { content: '\f279'; } /* '' */
